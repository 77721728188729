<template>
	<v-dialog
		v-model="dialog"
		width="1000"
		:fullscreen="$vuetify.breakpoint.xsOnly"
		persistent
		style="background-color: blue; border-radius: 30px !important"
	>
		<v-card flat style="overflow: hidden">
			<v-card-title class="secondary py-1" style="height: 40px">
				<span class="dialog-title">{{$t('medicalTeam')}}</span>
				<v-spacer />
				<v-icon small color="primary" @click="$emit('close')">mdi-window-close</v-icon>
			</v-card-title>
			<v-card-text color="primary" class="mt-3" >
				<v-form>
					<v-row dense>
						<v-col cols="12" lg="6" md="6" sm="12">
							<v-text-field
								v-model="searchPatient"
								append-icon="mdi-magnify"
								:label="$t('search-Patient')"
								single-line
								hide-details
								class="my-1"
							></v-text-field>
							<v-data-table
								dense
								fixed-header
								:items="patients"
								item-key="item.id"
								:no-data-text="$t('no-data-available')"
								:loading-text="$t('loading')"
								hide-default-footer
								height="440"
								:search="searchPatient"
								:headers="patientHeadr"
								v-model="selectedRows"
								style="width: 500px;"
								:items-per-page="-1"
								class="elevation-1"
								:footer-props="{
									itemsPerPageText: this.$t('row'),
									itemsPerPageAllText: this.$t('all'),
								}"
								>
								<template v-slot:item="{ item }">
									<tr  :style="selectedRows.indexOf(item)>-1?'color: white':'' " :class="selectedRows.indexOf(item)>-1?'primary':''" @click="rowClicked(item)">
										<td style="text-align: left;">{{item.firstName}} {{item.lastName}}</td>
										<td style="text-align: left;">{{item.email}}</td>
									</tr>
								</template>
							</v-data-table>
						</v-col>
						<v-col cols="12" lg="6" md="6" sm="12">
							<v-text-field
								v-model="searchMedicalTeam"
								append-icon="mdi-magnify"
								:label="$t('search-medical-team')"
								single-line
								class="my-1"
								hide-details
							></v-text-field>
							<v-data-table
								dense
								fixed-header
								:no-data-text="$t('no-data-available')"
								:loading-text="$t('loading')"
								:headers="staffHeadr"
								:items="staffData"
								hide-default-footer
								item-key="id"
								:search="searchMedicalTeam"
								height = "440"
								:items-per-page="-1"
								class="elevation-1"
								:footer-props="{
									itemsPerPageText: this.$t('row'),
									itemsPerPageAllText: this.$t('all'),
								}"
							>
							<template v-slot:item="{ item }">
									<tr>
										<td dense style="text-align: left;">
											<img
											class="mx-1"
											:src="
												item.role === 'Doctor'
												? doctor_black
												: nurse_black
											"
											color="primary"
											height="18px"
											/>
										{{item.fullName}}
										</td>
										<td dense>
										<v-checkbox
										class="pa-0 ma-0 d-flex justify-end"
										hide-details
											:disabled="item.id === primaryDoctorSelected ? true : false"
											v-model="staffSelected"
											color="primary"
											:value="item.id"
										></v-checkbox>
										</td>
										<td>
										<v-radio-group class="pa-0 ma-0" hide-details v-model="primaryDoctorSelected">
										<v-radio
										class="d-flex justify-center"
										v-if="item.roleId === 3"
											:key="item.id"
											:value="item.id"
											color="primary"
										></v-radio>
										</v-radio-group>
										</td>
									</tr>
								</template>

							</v-data-table>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-row dense class="d-flex justify-end py-3 mx-1" style="overflow: hidden">
				<span cols="12" lg="6" md="6" sm="12" >
				<v-btn class="table-cancel-button" @click="$emit('close')">
					<span color="black--text">{{ $t("cancel") }}</span>
				</v-btn>
				</span>
				<span cols="12" lg="6" md="6" sm="12">
				<v-btn
					class="table-create-button mx-1"
					:loading="loading"
					:disabled="loading || checkValidity"
					@click="loader = 'loading'"
				>
					<span>{{ $t("save") }}</span>
				</v-btn>
				</span>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { doctorBlack, nurseBlack } from '@/assets';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
	props: ['dialog', 'data', 'deniedPatients'],
	data () {
		return {
			loader: null,
			loading: false,
			medicalTemaName: null,
			selectedStaff: [],
			relatedPatients: [],
			staff: [],
			doctors: [],
			starterArray: [],
			selectedPatient: undefined,
			medicalTeamTitle: '',
			doctor_black: doctorBlack,
			nurse_black: nurseBlack,
			searchPatient: null,
			searchMedicalTeam: null,
			primaryDoctor: null,
			patients: [],
			primaryDoctorSelected: [],
			staffSelected: [],
			selectedRows: [],
			patientHeadr: [
				{
					text: this.$t('name'),
					align: 'start',
					value: 'fullName',
				},
				{
					text: this.$t('email-address'),
					align: 'start',
					value: 'checkBox',
				},
			],
			staffHeadr: [
				{
					text: this.$t('name'),
					align: 'start',
					value: 'fullName',
				},
				{
					text: this.$t('staff'),
					value: 'checkBox',
				},
				{
					text: this.$t('primary-doctor'),
					value: 'radioButton',
				},
			],

		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			relatedUsers: (state) => state.hospitals.users,
			relatedPatientsTemp: (state) => state.hospitals.relatedPatients,
		}),
		staffData () {
			return this.staff.filter(s => { return s.id !== this.primaryDoctor && s.status === 1; });
		},
		checkValidity () {
			if (this.medicalTemaName === null) {
				return true;
			}
			if (this.staffSelected.length === 0) {
				return true;
			}
			if (this.primaryDoctorSelected.length === 0) {
				return true;
			}
			return false;
		},
	},
	watch: {
		primaryDoctorSelected (val) {
			if (!this.staffSelected.includes(val)) {
				this.staffSelected.push(val);
			}
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.manageMedicalTeam()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	async mounted () {
		// await this.getRelatedUsers();
		if (this.relatedUsers == null) {
			await this.getRelatedUsers(); // we call this only if the data is not loaded, otherwise we use it from the store
		} else {
			if (this.relatedUsers !== null) {
				this.staff = this.relatedUsers
					.filter((users) => users.roleId === 3 || users.roleId === 4)
					.map(
						(users) =>
							(users = {
								id: users.id,
								fullName: users.firstName + ' ' + users.lastName,
								role: users.roleData?.roleNameForDisplay,
								roleId: users.roleId,
								status: users.status,
							}),
					);
				this.doctors = this.staff.filter(s => { return s.roleId === 3; });
			} else this.staff = [];
		}

		this.getPatients();
	},
	methods: {
		rowClicked (row) {
			this.toggleSelection(row);
			this.medicalTemaName = `${row.firstName} ${row.lastName}`;
			if (this.selectedRows.length > 1) {
				this.selectedRows.shift();
			}
		},
		toggleSelection (keyID) {
			if (this.selectedRows.includes(keyID)) {
				this.selectedRows = this.selectedRows.filter(
					selectedKeyID => selectedKeyID !== keyID,
				);
			} else {
				this.selectedRows.push(keyID);
			}
		},
		async getPatients () {
			await this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userDetails.hospitalAssignedData.id);

			if (this.relatedPatientsTemp !== null) {
				this.relatedPatientsTemp.forEach(item => {
					if (item.medicalTeamsData.length === 0) {
						item.forAdd = true;
						this.patients.push(item);
					} else if (item.medicalTeamsData[0].membersList.length === 0) {
						item.forAdd = false;
						this.patients.push(item);
					}
				});
			}
		},
		async getRelatedUsers () {
			await this.$store
				.dispatch(
					'hospitals/getRelatedUsersForHospital',
					this.userDetails.hospitalAssignedData.id,
				)
				.then(() => {
					if (this.relatedUsers !== null) {
						this.staff = this.relatedUsers
							.filter((users) => users.roleId === 3 || users.roleId === 4)
							.map(
								(users) =>
									(users = {
										id: users.id,
										fullName: users.firstName + ' ' + users.lastName,
										role: users.roleData?.roleNameForDisplay,
										roleId: users.roleId,
										status: users.status,
									}),
							);
						this.doctors = this.staff.filter(s => { return s.roleId === 3; });

						this.relatedPatientsTemp.forEach(item => {
							if (item.medicalTeamsData.length === 0) {
								item.forAdd = true;
								this.patients.push(item);
							} else if (item.medicalTeamsData[0].membersList.length === 0) {
								item.forAdd = false;
								this.patients.push(item);
							}
						});
					} else this.staff = [];
				});
		},
		async manageMedicalTeam () {
			if (this.selectedRows[0].forAdd) {
				const body = {
					hospitalId: this.userDetails.hospitalAssignedData.id,
					patientId: this.selectedRows[0].id,
					primaryDoctorId: this.primaryDoctorSelected,
					medicalTeamTitle: this.medicalTemaName + ' Team',
					medicalTeamDescription: this.medicalTemaName + ' Team',
					status: 1,
				};
				await this.$store
					.dispatch('medicalteam/createMedicalTeam', body)
					.then(async (res) => {
						if (res) {
							showSuccessAlert(this.$t('success'));
							await this.assignMemberToMedicalTeam(res.id);
						} else {
							showErrorAlert(this.$t('failed'));
						}
					});
			} else {
				this.assignMemberToMedicalTeam(this.selectedRows[0].medicalTeamsData[0].id);
			}
		},
		async assignMemberToMedicalTeam (medicalTeamId) {
			const bodyAssign = {
				medicalTeamId: medicalTeamId,
				memberUserIdList: this.staffSelected,
			};
			await this.$store.dispatch('medicalteam/assignMultipleMembersToMedicalTeam', bodyAssign);

			this.$emit('false');
		},
	},
};
</script>
