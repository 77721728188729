<template>
    <v-dialog
        v-model="dialog"
        width="800"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        style="background-color: blue; border-radius: 30px !important"
    >
        <v-form v-model="valid">
            <v-card flat style="overflow: hidden">
                <v-card-title class="secondary py-1" style="height: 40px">
                    <span class="dialog-title">{{$t('staff')}} {{$t('details')}}</span>
                    <v-spacer />
                    <v-icon small color="primary" @click="$emit('close')"
                        >mdi-window-close</v-icon
                    >
                </v-card-title>
                <v-card-text>
                    <v-row class="pl-6 pt-6 pb-0">
                        <v-col class="col-left-alignment">
                            <span style="font-size: 16px;" color="main_black">{{$t('personal')}} {{$t('information')}}</span>
                        </v-col>
                    </v-row>
                    <v-row dense class="px-6 pt-0 pb-2">
                        <v-col>
                            <v-text-field
                                v-model="user.firstName"
                                :label="this.$t('name')"
                                prepend-inner-icon="mdi-account mr-1"
                                dense
                                background-color="#E8E8E8"
                                rounded
                                outlined
                                style="border-radius: 12px !important; height: 40px"
                                :rules="[(rule) => !!rule || '']"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="user.lastName"
                                :label="this.$t('surname')"
                                prepend-inner-icon="mdi-account mr-1"
                                dense
                                background-color="#E8E8E8"
                                rounded
                                outlined
                                style="border-radius: 12px !important; height: 40px"
                                :rules="[(rule) => !!rule || '']"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="px-6 pb-2">
                        <v-col>
                            <validation-provider rules="required|email" :name="$t('email')" v-slot="{ errors }">
                                <v-text-field
                                    v-model="user.email"
                                    :label="$t('email')"
                                    prepend-inner-icon="mdi-email mr-1"
                                    dense
                                    background-color="#E8E8E8"
                                    rounded
                                    disabled
                                    outlined
                                    style="border-radius: 12px !important; height: 40px"
                                    :error-messages="emailError = errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <v-text-field
                                    ref="phoneNumber"
                                    v-model="user.phone"
                                    validate-on-blur
									:label="$t('header-phone')"
                                    prepend-inner-icon="mdi-cellphone mr-4"
                                    dense
                                    background-color="#E8E8E8"
                                    rounded
                                    outlined
                                    style="border-radius: 12px !important; height: 40px"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
					<v-row dense>
						<v-col cols="6">
						<v-btn-toggle
							class="my-2 justify-start"
							v-model="languageStaff"
							row
							mandatory
							active-class="primary white--text"
							rounded>
							<v-btn
								value='ENG'
								style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
							>{{$t('english')}}</v-btn>
							<v-btn
								value='ESP'
								style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
							>{{$t('spanish')}}</v-btn>
						</v-btn-toggle>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<v-btn-toggle
								class="my-2 justify-start"
								v-model="scaleAndTempUnit"
								row
								mandatory
								active-class="primary white--text"
								rounded>
								<v-btn
									value='IMPERIAL'
									style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
									@click="setImperialOrMetric('IMPERIAL')"
								>{{$t('imperial')}}</v-btn>
								<v-btn
									value='METRIC'
									style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
									@click="setImperialOrMetric('METRIC')"
								>{{$t('metric')}}</v-btn>
							</v-btn-toggle>
						</v-col>
						<v-col cols="6">
							<v-btn-toggle
								class="my-2 justify-start"
								v-model="glucoseAndCholesterolUnit"
								row
								mandatory
								active-class="primary white--text"
								rounded>
								<v-btn
									value='mg/dl'
									style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
									@click="setGlucoseAndCholesterolUnit('mg/dl')"
								>{{$t('glucose-mg-dL')}}</v-btn>
								<v-btn
									value='mmol/l'
									style="width: 116px; font-size: 10px; justify-content: center; height: 20px"
									@click="setGlucoseAndCholesterolUnit('mmol/l')"
								>{{$t('glucose-mmol-L')}}</v-btn>
							</v-btn-toggle>
						</v-col>
					</v-row>
                    <!-- <v-row class="pl-6 pt-0 pb-0">
                        <v-col class="col-left-alignment">
                            <span style="font-size: 16px;" color="main_black">{{$t('hospital')}}</span>
                        </v-col>
                    </v-row> -->
                    <v-row dense class="px-6 pb-2">
                        <v-col>
                            <v-text-field
                                ref="hospital"
                                v-model="hospital"
                                validate-on-blur
                                :label="$t('office')"
                                prepend-inner-icon="mdi-office-building mr-4"
                                dense
                                background-color="#E8E8E8"
                                rounded
                                outlined
                                :readonly="true"
                                style="border-radius: 12px !important; height: 40px"
                                :rules="[(rule) => !!rule || '']"
                            ></v-text-field>
                        </v-col>
                      <!-- <v-col>
                        <v-autocomplete
                            v-model="user.userTimezone"
                            :label="$t('timezone')"
                            dense
                            :items="timeZones"
                            item-text="timeZoneId"
                            item-value="timezone"
                            background-color="#E8E8E8"
                            rounded
                            outlined
                            style="border-radius: 12px !important; height: 40px"
                        ></v-autocomplete>
                      </v-col> -->
                    </v-row>
                    <!-- <v-row class="pl-6 pt-0 pb-0">
                        <v-col class="col-left-alignment">
                            <span style="font-size: 16px;" color="main_black">{{$t('status')}}</span>
                        </v-col>
                    </v-row> -->
                    <!-- <v-row dense class="px-6 pb-2">
                        <v-col>
                            <v-select
                                v-model="user.status"
                                :items="status"
                                name="status"
                                item-text="key"
                                dense
                                background-color="#E8E8E8"
                                style="border-radius: 12px !important;"
                                outlined
                            >
                            </v-select>
                        </v-col>
                    </v-row> -->
                </v-card-text>
                <v-card-actions>
                    <Alert class="ml-6" v-if="showErrors" :title="title" :type="alertType"></Alert>
                  <span class="my-5 mx-10 text-left"></span>
                    <v-spacer></v-spacer>
                    <div class="my-6 mx-6">
                        <v-btn class="table-cancel-button" @click="$emit('close')">
                            <span color="black--text">{{ $t("cancel") }}</span>
                        </v-btn>
						<v-btn
							class="table-create-button mx-2"
							:loading="loading"
							:disabled="loading"
							@click="loader = 'loading'"
						>
							<span color="white--text">{{ $t("save") }}</span>
						</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import Alert from '@/views/Client/components/AlertDialog.vue';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { mapState } from 'vuex';

export default {
	components: {
		Alert,
	},
	props: ['dialog', 'user', 'hospital'],
	data () {
		return {
			loader: null,
			loading: false,
			valid: false,
			timezone: '',
			status: [
				{
					key: 'Active',
					value: 1,
				},
				{
					key: 'Inactive',
					value: 0,
				},
			],
			showErrors: false,
			title: 'Something went wrong',
			alertType: false,
			validationErrors: [],
			emailError: false,
			phoneNumberError: false,
			languageStaff: 'ENG',
			scaleAndTempUnit: 'IMPERIAL',
			glucoseAndCholesterolUnit: 'mg/dl',
		};
	},
	computed: {
		...mapState({
			timeZones: (state) => state.verifiers.timeZones,
		}),
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.update()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		'user.phone': function (val) {
			if (!isValidPhoneNumber(val)) {
				this.phoneNumberError = true;
			} else {
				this.phoneNumberError = false;
			}
			this.user.phone = new AsYouType().input(val);
		},
	},
	mounted () {
		this.languageStaff = this.user.language;

		this.scaleAndTempUnit = (JSON.parse(this.user.userSettingsJson)).Units;
		this.glucoseAndCholesterolUnit = (JSON.parse(this.user.userSettingsJson)).Concentration;
		this.userStatus = this.user.status;
		this.getTimeZones();
	},
	methods: {
		setImperialOrMetric (unit) {
			this.scaleAndTempUnit = unit;
			this.getPreferences();
		},
		setGlucoseAndCholesterolUnit (unit) {
			this.glucoseAndCholesterolUnit = unit;
			this.getPreferences();
		},
		getPreferences () {
			const preferences = {
				Units: this.scaleAndTempUnit,
				Concentration: this.glucoseAndCholesterolUnit,
			};
			return preferences;
		},
		async update () {
			const valid = this.checkValid();
			if (!valid) {
				this.title = 'Provided data is not valid: ' + this.validationErrors.toString();
				this.alertType = 'error';
				this.showErrors = true;
				return;
			}
			const body = {
				firstName: this.user.firstName,
				lastName: this.user.lastName,
				phone: this.user.phone.split(' ').join(''),
				email: this.user.email,
				address: this.user.address,
				zip: this.user.postal_code,
				city: this.user.city,
				state: this.user.state,
				country: this.user.country,
				addressLatitude: this.user.addressLatitude,
				addressLongitude: this.user.addressLongitude,
				username: this.user.email,
				gender: this.user.gender,
				birthdate: this.user.birthdate,
				roleId: this.user.roleId,
				status: this.user.status,
				userTimezone: this.user.userTimezone,
				// userSettingsJson: this.user.userSettingsJson,
				userSettingsJson: JSON.stringify(this.getPreferences()),
				id: this.user.id,
				language: this.languageStaff,
			};
			const sendValidationEmail = { email: this.user.email };
			await this.$store.dispatch('users/updateUser', body)
				.then(async res => {
					if (this.userStatus === 0 && this.user.status === 1) {
						await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail);
					}
					this.$emit('updated', res);
				});
		},
		async locationDetails () {
			if (this.user.address) {
				this.waiting = true;
				const geoLocation = await this.$store.dispatch(
					'googleAPI/getGeoLocation',
					this.user.address,
				);
				this.waiting = false;
				if (!geoLocation) {
					this.validationErrors.push('address');
					this.address_info = {
						address: '',
						postal_code: '',
						city: '',
						state: '',
						country: '',
						lat: 0,
						lng: 0,
					};
					return;
				}
				const addressComponents = geoLocation.address;
				this.address_info.lat = geoLocation.lat;
				this.address_info.lng = geoLocation.lon;
				this.formattedAddress = geoLocation.display_name;
				this.address_info.address = this.formattedAddress;
				this.address_info.city = addressComponents.city;
				this.address_info.state = addressComponents.state;
				this.address_info.country = addressComponents.country;
				this.address_info.postal_code = addressComponents.postcode;
				if (this.address_info.lat !== null) {
					const tz = await this.timeZone(
						this.address_info.lat,
						this.address_info.lng,
					);
					if (tz) {
						this.timezone = tz.zoneName;
					}
				}
			}
		},
		async getTimeZones () {
			await this.$store.dispatch('verifiers/getTimeZones').then(() => {
			});
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
			const timeZone = await this.$store.dispatch('googleAPI/getTimeZone', object);
			return timeZone;
		},
		checkValid () {
			this.validationErrors = [];
			this.title = '';
			this.showErrors = false;
			if (this.phoneNumberError) {
				this.validationErrors.push('phone');
			}
			if (this.emailError.length > 0) {
				this.validationErrors.push('email');
			}
			if (this.user.address === '') {
				this.validationErrors.push('address');
			}
			if (this.validationErrors.length > 0) {
				return false;
			} else return true;
		},
	},
};
</script>
