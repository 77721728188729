<template>
	<v-dialog
		v-model="dialog"
		width="500"
		:fullscreen="$vuetify.breakpoint.xsOnly"
		persistent
		style="background-color: blue; border-radius: 30px !important"
	>
		<v-card flat style="overflow: hidden">
			<v-card-title class="secondary py-1" style="height: 40px">
				<span class="dialog-title">{{$t('medicalTeam')}}</span>
				<v-spacer />
				<v-icon small color="primary" @click="$emit('close')"
				>mdi-window-close</v-icon
				>
			</v-card-title>
			<v-card-text color="primary" class="mt-3" >
				<v-form>
					<v-row dense class="pt-5">
						<v-col>
						<v-col
					>
						<v-text-field
						:value="medicalTemaName"
						:label="$t('medical-team-for')"
						filled
						disabled
						style="width: 100%;"
						></v-text-field>
					</v-col>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" lg="12" md="12" sm="12">
						<v-text-field
							v-model="searchMedicalTeam"
							append-icon="mdi-magnify"
							:label="$t('search-medical-team')"
							single-line
							class="my-1"
							hide-details
						></v-text-field>
						<v-data-table
							dense
							fixed-header
							:no-data-text="$t('no-data-available')"
				:loading-text="$t('loading')"
							:headers="staffHeadr"
							:items="staffData"
							hide-default-footer
							item-key="id"
							:search="searchMedicalTeam"
							height = "440"
							style="width: 100%;"
							:items-per-page="-1"
							class="elevation-1"
							:footer-props="{
					itemsPerPageText: this.$t('row'),
					itemsPerPageAllText: this.$t('all'),
					}"
						>
						<template v-slot:item="{ item }">
								<tr>
									<td dense style="text-align: left;">
										<img
										class="mx-1"
										:src="
											item.role === 'Doctor'
											? doctor_black
											: nurse_black
										"
										color="primary"
										height="18px"
										/>
									{{item.fullName}}
									</td>
									<td dense>
									<v-checkbox
										v-if="item.id !== primaryDoctorSelected"
									class="pa-0 ma-0 d-flex justify-end"
									hide-details
										v-model="staffSelected"
										color="primary"
										:value="item.id"
									></v-checkbox>
									<v-checkbox v-else
										class="pa-0 ma-0 d-flex justify-end"
										hide-details
										disabled
										v-model="checkbox"
										color="primary"
									></v-checkbox>
									</td>
									<td>
									<v-radio-group class="pa-0 ma-0" hide-details v-model="primaryDoctorSelected">
									<v-radio
									class="d-flex justify-center"
									v-if="item.roleId === 3"
										:key="item.id"
										:value="item.id"
										color="primary"
									></v-radio>
									</v-radio-group>
									</td>
								</tr>
							</template>
						</v-data-table>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-row dense class="d-flex justify-end py-3 mx-1" style="overflow: hidden">
				<span cols="12" lg="6" md="6" sm="12" >
				<v-btn class="table-cancel-button" @click="$emit('close')">
					<span color="black--text">{{ $t("cancel") }}</span>
				</v-btn>
				</span>
				<span cols="12" lg="6" md="6" sm="12">
				<v-btn
					class="table-create-button mx-1"
					:loading="loading"
					:disabled="loading || checkValidity"
					@click="loader = 'loading'"
				>
					<span>{{ $t("save") }}</span>
				</v-btn>
				</span>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { doctorBlack, nurseBlack } from '@/assets';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
	props: ['dialog', 'data', 'deniedPatients', 'item'],
	data () {
		return {
			loader: null,
			loading: false,
			medicalTemaName: null,
			checkbox: true,
			hospitalId: null,
			selectedStaff: [],
			staff: [],
			patientId: null,
			addArray: [],
			deleteArray: [],
			doctors: [],
			selectedPatient: undefined,
			medicalTeamTitle: '',
			doctor_black: doctorBlack,
			nurse_black: nurseBlack,
			searchPatient: null,
			searchMedicalTeam: null,
			primaryDoctor: null,
			primaryDoctorSelected: null,
			newStafSelected: [],
			staffSelected: [],
			starterArray: null,
			selectedRows: [],
			patientHeadr: [
				{
					text: this.$t('name'),
					align: 'start',
					value: 'fullName',
				},
				{
					text: this.$t('email-address'),
					align: 'start',
					value: 'checkBox',
				},
			],
			staffHeadr: [
				{
					text: this.$t('name'),
					align: 'start',
					value: 'fullName',
				},
				{
					text: this.$t('staff'),
					value: 'checkBox',
				},
				{
					text: this.$t('primary-doctor'),
					value: 'radioButton',
				},
			],

		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			relatedUsers: (state) => state.hospitals.users,
			relatedPatients: (state) => state.hospitals.relatedPatients,
			patientData: state => state.medicalteam.medicalTeams,
			selectedMedicalTeam: state => state.medicalteam.selectedMedicalTeam,
		}),
		patients () {
			return this.relatedPatients?.filter(
				(patient) => !this.deniedPatients.includes(patient.id),
			);
		},
		staffData () {
			return this.staff.filter(s => { return s.id !== this.primaryDoctor && s.status === 1; });
		},
		checkValidity () {
			if (this.medicalTemaName === '') {
				return true;
			}
			if (this.staffSelected.length === 0) {
				return true;
			}
			if (this.primaryDoctorSelected === null) {
				return true;
			}
			return false;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.manageMedicalTeam()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	async mounted () {
		this.patientDetails();

		// await this.getRelatedUsers();
		if (this.relatedUsers == null) {
			await this.getRelatedUsers(); // we call this only if the data is not loaded, otherwise we use it from the store
		} else {
			this.staff = this.relatedUsers
				.filter((users) => users.roleId === 3 || users.roleId === 4)
				.map(
					(users) =>
						(users = {
							id: users.id,
							fullName: users.firstName + ' ' + users.lastName,
							role: users.roleData?.roleNameForDisplay,
							roleId: users.roleId,
							status: users.status,
						}),
				);
			this.doctors = this.staff.filter(s => { return s.roleId === 3; });
		}

		// await this.getPatients(); // no need to call this, we use the data from the store
	},
	methods: {
		patientDetails () {
			// this.$store.dispatch(
			// 	'medicalteam/getMedicalTeamForPatient',
			// 	this.item,
			// ).then(res => {
			// 	this.hospitalId = res.medicalTeamsData[0].hospitalId;
			// 	this.patientId = res.medicalTeamsData[0].patientId;
			// 	this.primaryDoctorSelected = res.medicalTeamsData[0].primaryDoctorId;
			// 	for (let i = 0; i < res.medicalTeamsData[0].membersList.length; i++) {
			// 		if (res.medicalTeamsData[0].membersList[i]?.id) {
			// 			this.staffSelected.push(res.medicalTeamsData[0].membersList[i].id);
			// 		}
			// 	}
			// 	this.starterArray = this.staffSelected;
			// 	this.medicalTemaName = res.medicalTeamsData[0].patientFullname + ' Team';
			// });

			// we are getting now from the store, NOT with new API call
			this.hospitalId = this.selectedMedicalTeam.hospitalId;
			this.patientId = this.selectedMedicalTeam.patientId;
			this.primaryDoctorSelected = this.selectedMedicalTeam.primaryDoctorId;
			for (let i = 0; i < this.selectedMedicalTeam.membersList.length; i++) {
				if (this.selectedMedicalTeam.membersList[i]?.id) {
					this.staffSelected.push(this.selectedMedicalTeam.membersList[i].id);
				}
			}
			this.starterArray = this.staffSelected;
			this.medicalTemaName = this.selectedMedicalTeam.patientFullname + ' Team';
		},
		rowClicked (row) {
			this.toggleSelection(row.id);
			this.medicalTemaName = `${row.firstName} ${row.lastName}`;
			if (this.selectedRows.length > 1) {
				this.selectedRows.shift();
			}
		},
		toggleSelection (keyID) {
			if (this.selectedRows.includes(keyID)) {
				this.selectedRows = this.selectedRows.filter(
					selectedKeyID => selectedKeyID !== keyID,
				);
			} else {
				this.selectedRows.push(keyID);
			}
		},
		async getPatients () {
			await this.$store.dispatch(
				'hospitals/getRelatedPatients',
				this.userDetails.hospitalAssignedData.id,
			);
		},
		async getRelatedUsers () {
			await this.$store
				.dispatch('hospitals/getRelatedUsersForHospital', this.userDetails.hospitalAssignedData.id)
				.then(() => {
					if (this.relatedUsers !== null) {
						this.staff = this.relatedUsers
							.filter((users) => users.roleId === 3 || users.roleId === 4)
							.map(
								(users) =>
									(users = {
										id: users.id,
										fullName: users.firstName + ' ' + users.lastName,
										role: users.roleData?.roleNameForDisplay,
										roleId: users.roleId,
										status: users.status,
									}),
							);
						this.doctors = this.staff.filter(s => { return s.roleId === 3; });
					} else this.staff = [];
				});
		},
		async manageMedicalTeam () {
			if (!this.staffSelected.includes(this.primaryDoctorSelected)) {
				this.staffSelected.push(this.primaryDoctorSelected);
			}
			if (!this.starterArray.includes(this.primaryDoctorSelected)) {
				this.starterArray.push(this.primaryDoctorSelected);
			}
			const body = {
				hospitalId: this.userDetails.hospitalAssignedData.id, // this.hospitalId,
				patientId: this.patientId,
				primaryDoctorId: this.primaryDoctorSelected,
				medicalTeamTitle: this.medicalTemaName,
				medicalTeamDescription: this.medicalTeamDescription,
				status: 1,
			};
			await this.$store
				.dispatch('medicalteam/editMedicalTeam', body)
				.then(async (res) => {
					if (res) {
						showSuccessAlert(this.$t('success'));

						const medicalTeamId = res.id;

						const bodyDeassign = {
							medicalTeamId: medicalTeamId,
							memberUserIdList: this.starterArray,
						};
						await this.$store.dispatch('medicalteam/deassignMultipleMembersFromMedicalTeam', bodyDeassign);

						const bodyAssign = {
							medicalTeamId: medicalTeamId,
							memberUserIdList: this.staffSelected,
						};
						await this.$store.dispatch('medicalteam/assignMultipleMembersToMedicalTeam', bodyAssign);

						this.$store.commit('medicalteam/SELECTED_MEDICAL_TEAM', null); // clear selectedMedicalTeam from the store
						this.$emit('false');
					} else {
						showErrorAlert(this.$t('failed'));
					}
				});
		},
	},
};
</script>
