<template>
    <v-dialog
        v-model="dialog"
        width="720"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        class="dialog-style"
    >
      <v-card style="overflow-y:auto;">
        <v-card-title class="secondary py-1" style="height: 40px">
            <span class="dialog-title mx-2" style="font-size: 14px">{{$t('assign-user-to-office')}}</span>
            <v-spacer />
            <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text>
            <v-row class="p-5">
                <v-col cols="12">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                       {{$t('Hospital')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in hospitals"
                                    :key="item.id"
                                    @click="selectedBranch(item)"
                                    style="cursor:pointer;"
                                    >
                                    <td class="text-left">{{ item.hospitalName }}</td>
                                </tr>
                            </tbody>
                        </template>
                      </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: ['dialog', 'specializations', 'staffUser'],
	components: {
		// BirthdatePicker,
	},
	data () {
		return {
			loader: null,
			loading: false,
			valid: false,
			title: null,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			hospitalsFromRepo: (state) => state.hospitals.hospitals,
		}),
		hospitals () {
			// we filter only those hospitals that are NOT already assigned, those thar are ssigned we are not showing as option
			var result = [];
			if (this.hospitalsFromRepo !== null) {
				for (let i = 0; i < this.hospitalsFromRepo.length; i++) {
					const hospitalIdToCheck = this.hospitalsFromRepo[i].id;
					var found = false;
					for (let j = 0; j < this.staffUser.hospitalsAssignedData.length; j++) {
						if (this.staffUser.hospitalsAssignedData[j].id === hospitalIdToCheck) {
							found = true;
							break;
						}
					}
					if (found === false) {
						result.push(this.hospitalsFromRepo[i]);
					}
				}
			}
			return result;
		},
	},
	async mounted () {
		await this.getHospitals();
	},
	methods: {
		async selectedBranch (selectedHospital) {
			const body = { HospitalId: selectedHospital.id, UserId: this.staffUser.id };
			const result = await this.$store.dispatch('hospitals/assignUserToHospital', body);
			result.hospitalName = selectedHospital.hospitalName; // we attach also hospitalName to the response back
			this.$emit('result', result);
		},
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals');
		},
	},
};
</script>
