<template>
  <v-row class="mx-2 my-2">
    <v-col cols="12" xl="4" lg="4" md="12" sm="12">
      <v-card style="border-radius: 10px; overflow-y: auto;" width="100%" :height="cardHeight">
        <v-card-title class="secondary py-1" style="position:sticky; top:0;">
          <span class="dialog-title">{{$t('staff')}} {{$t('details')}}</span>
          <v-spacer />
          <v-btn
            v-if="type === 'mstaff'"
            x-small
            elevation="0"
            color="secondary"
            @click="editstaffdetails"
          >
            <v-icon small color="primary">mdi-pencil</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('name')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                item.fullName
              }}</span>
            </v-col>
          </v-row>
          <!-- <v-row dense>
                        <v-col class="table-cols" cols="12" xl="4" lg="4" md="8" sm="8">
                            Address:
                        </v-col>
                        <v-col class="table-cols-right" cols="12" xl="8" lg="8" md="8" sm="8">
                            <span style="font-weight: 600;" class="main_black--text">{{ item.address }}, {{ item.zip }}, {{ item.city }}, {{ item.countryName }}</span>
                        </v-col>
                    </v-row> -->
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('email')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                item.email
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('phone')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                item.phone
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('status')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                item.status === 0 ? this.$t('inactive') : this.$t('active')
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="patientId !== undefined"
      cols="12"
      xl="4"
      lg="4"
      md="12"
      sm="12"
    >
      <v-card
        style="border-radius: 10px !important; overflow-y: auto;"
        :loading="patientloading"
        :height="cardHeight"
      >
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title">{{$t('patient')}} {{$t('details')}}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text class="pt-5">
          <v-row v-if="patientDetails" dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('patient')}} {{$t('name')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text"
                >{{ patientDetails.firstName }}
                {{ patientDetails.lastName }}</span
              >
            </v-col>
          </v-row>
          <v-row v-if="patientDetails" dense>
            <v-col class="table-cols" cols="12" xl="3" lg="3" md="3" sm="3">
              {{$t('patient')}} {{$t('email')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="9"
              lg="9"
              md="9"
              sm="9"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                patientDetails.email
              }}</span>
            </v-col>
          </v-row>
          <v-row v-if="patientMetaData" dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('medicalRegistrationNumber')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                patientMetaData.medicalRegistrationNumber
              }}</span>
            </v-col>
          </v-row>
          <v-row v-if="patientMetaData" dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('smoker')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                patientMetaData.isCigaretteSmoker ? "Yes" : "No"
              }}</span>
            </v-col>
          </v-row>
          <v-row v-if="patientMetaData" dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('height')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">
                {{ userSettings.Units === "METRIC" ? patientMetaData.heightInCm +' cm' : toFeet(patientMetaData.heightInCm) }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="patientMetaData" dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('weight')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text"
                >{{
                  userSettings.Units === "METRIC"
                    ? patientMetaData.weightInKg
                    : (patientMetaData.weightInKg * 2.20462).toFixed(0)
                }}
                {{ userSettings.Units === "METRIC" ? "kg" : "lbs" }}</span
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-else-if="roleName !== 'ORGANIZATION_ADMIN' && hospitalData !== null" cols="12" xl="4" lg="4" md="12" sm="12">
      <v-card style="border-radius: 10px !important; overflow-y: auto" :height="cardHeight">
        <v-card-title class="secondary py-1" style="position: sticky; top: 0;">
          <span class="dialog-title">{{$t('hospital')}} {{$t('details')}}</span>
          <v-spacer />
        </v-card-title>
        <v-card-text class="pt-5">
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('hospital')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                hospitalData.hospitalName
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('address')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                hospitalData.address
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('contact')}} {{$t('person')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                hospitalData.contactPerson
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('contact')}} {{$t('phone')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                hospitalData.contactPhone
              }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
              {{$t('contact')}} {{$t('email')}}:
            </v-col>
            <v-col
              class="table-cols-right"
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
            >
              <span style="font-weight: 600" class="main_black--text">{{
                hospitalData.contactEmail
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <MedicalStaffDetailsDialog
        v-if="editmedicalstaffdialog"
        :dialog="editmedicalstaffdialog"
        :user="edituser"
        :hospital="hospitalData.hospitalName"
        @close="editmedicalstaffdialog = false"
        @updated="updatedstaffdetails"
      ></MedicalStaffDetailsDialog>
      <MedicalStaffDataDialog
        v-if="editmedicaldatadialog"
        :dialog="editmedicaldatadialog"
        :data="editdata"
        :role="item.roleId"
        @close="editmedicaldatadialog = false"
        @updated="updatedstaffdata"
      ></MedicalStaffDataDialog>
    </v-col>
    <v-col v-else-if="roleName === 'ORGANIZATION_ADMIN'" cols="12" xl="4" lg="4" md="12" sm="12">
      <v-card style="border-radius: 10px; overflow-y: auto" :height="cardHeight">
        <v-card-title class="secondary py-1" style="position:sticky; top:0; z-index:1">
          <span class="dialog-title">{{$t('hospital')}} {{$t('details')}}</span>
          <v-spacer />
          <v-btn
            v-if="type === 'mstaff'"
            x-small
            elevation="0"
            color="secondary"
            @click="showDialogAssignHospitalToUser"
          >
            <v-icon  small color="primary">mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <div v-if="item.hospitalsAssignedData.length > 1">
            <v-row dense>
              <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                {{$t('assigned-offices')}}:
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="table-cols" cols="12" xl="12" lg="12" md="12" sm="12">
                <v-simple-table dense>
                  <template v-slot:default>
                      <thead>
                      </thead>
                      <tbody>
                          <tr
                              v-for="hospitalAssigned in item.hospitalsAssignedData"
                              :key="hospitalAssigned.id"
                              @click="selectedBranch(hospitalAssigned)"
                              >
                              <!-- <td class="text-left">{{ hospitalAssigned.id }}</td> -->
                              <td class="text-left" style="font-weight: 600">{{ hospitalAssigned.hospitalName }}</td>
                              <td class="text-right">
                                  <v-icon
                                  elevation="0"
                                  color="primary"
                                  @click="deAssignHospital(hospitalAssigned)">
                                  mdi-delete</v-icon>
                              </td>
                          </tr>
                      </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row dense>
              <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                {{$t('hospital')}}:
              </v-col>
              <v-col
                class="table-cols-right"
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="6"
              >
                <span style="font-weight: 600" class="main_black--text">{{
                  item.hospitalsAssignedData[0].hospitalName
                }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                {{$t('address')}}:
              </v-col>
              <v-col
                class="table-cols-right"
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="6"
              >
                <span style="font-weight: 600" class="main_black--text">{{
                  item.hospitalsAssignedData[0].address
                }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                {{$t('contact')}} {{$t('person')}}:
              </v-col>
              <v-col
                class="table-cols-right"
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="6"
              >
                <span style="font-weight: 600" class="main_black--text">{{
                  item.hospitalsAssignedData[0].contactPerson
                }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                {{$t('contact')}} {{$t('phone')}}:
              </v-col>
              <v-col
                class="table-cols-right"
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="6"
              >
                <span style="font-weight: 600" class="main_black--text">{{
                  item.hospitalsAssignedData[0].contactPhone
                }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="table-cols" cols="12" xl="6" lg="6" md="6" sm="6">
                {{$t('contact')}} {{$t('email')}}:
              </v-col>
              <v-col
                class="table-cols-right"
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="6"
              >
                <span style="font-weight: 600" class="main_black--text">{{
                  item.hospitalsAssignedData[0].contactEmail
                }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <MedicalStaffDetailsDialog
        v-if="editmedicalstaffdialog"
        :dialog="editmedicalstaffdialog"
        :user="edituser"
        :hospital="edituser.hospitalsAssignedData[0].hospitalName"
        @close="editmedicalstaffdialog = false"
        @updated="updatedstaffdetails"
      ></MedicalStaffDetailsDialog>
      <MedicalStaffDataDialog
        v-if="editmedicaldatadialog"
        :dialog="editmedicaldatadialog"
        :data="editdata"
        :role="item.roleId"
        @close="editmedicaldatadialog = false"
        @updated="updatedstaffdata"
      ></MedicalStaffDataDialog>
      <AssignHospitalToUserDialog
        v-if="assignHospitalDialog"
        :dialog="assignHospitalDialog"
        :staffUser="item"
        @false="assignHospitalDialog = false"
        @close="closeAssignHospitalDialog"
		@result="resultBackAssignedHospital"
      ></AssignHospitalToUserDialog>
    </v-col>
    <v-col v-if="item.roleId === 3" cols="12" xl="4" lg="4" md="12" sm="12">
      <v-card
        :loading="loadingStaffData"
        :height="cardHeight"
        style="border-radius: 10px !important"
      >
        <v-card-title class="secondary py-1">
          <span class="dialog-title">{{$t('specializations')}}</span>
          <v-spacer />
          <v-btn
            v-if="type === 'mstaff' && item.roleId === 3"
            x-small
            elevation="0"
            color="secondary"
            @click="editstaffdata"
          >
            <v-icon small color="primary">mdi-pencil</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <!-- <v-row v-if="itemSpecializations" dense>
                        <v-col class="table-cols-right" cols="12" xl="6" lg="6" md="6" sm="6">
                        </v-col>
                    </v-row> -->
          <v-row v-if="itemSpecializations" dense>
            <div class="specialization-tabs2">
              <v-chip
                v-for="spec in item.doctorMetadataReadDto.spetializationData"
                class="mx-1 my-1"
                color="primary"
                :key="spec.spetializationId"
              >
                {{ getSpecializationDescription(spec) }}
              </v-chip>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MedicalStaffDetailsDialog from '@/views/dialogs/MedicalStaffDetailsDialog';
import MedicalStaffDataDialog from '@/views/dialogs/MedicalStaffDataDialog.vue';
import AssignHospitalToUserDialog from '@/views/dialogs/AssignHospitalToUserDialog';
import { fromTo } from '@/utils/unit-converter.js';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	components: {
		MedicalStaffDetailsDialog,
		MedicalStaffDataDialog,
		AssignHospitalToUserDialog,
	},
	props: ['item', 'patientId', 'type'],
	data () {
		return {
			patientloading: false,
			editmedicalstaffdialog: false,
			edituser: null,
			editmedicaldatadialog: false,
			editdata: null,
			loadingStaffData: false,
			assignHospitalDialog: false,
		};
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
			doctormetadata: (state) => state.doctormetadata.metaData,
			hospitalData: (state) => state.hospitals.hospitalData,
			nursemetadata: (state) => state.nursemetadata.nursemetadata,
			organizationData: (state) => state.medicalInstitutions.organizationData,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			userSettings: 'authentication/getUserSettings',
			delay: 'authentication/getDelay',
		}),
		currentLocale () {
			return this.$i18n.locale === 'en' ? 'spetializationDesc' : 'spetializationDescEsp';
		},
		cardHeight () {
			return 260;
		},
		itemWorkDetails () {
			if (this.type === 'mstaff') {
				if (this.item.roleId === 3) {
					return this.doctormetadata;
				} else return this.nursemetadata;
			} else {
				if (this.item.roleId === 3) {
					return this.item.doctorMetadataReadDto;
				} else return this.item.nurseMetadata;
			}
		},
		hospitalName () {
			if (this.detailedUser === null) {
				return this.item?.medicalTeamsData[0]?.hospitalName;
			}
			return this.detailedUser?.medicalTeamData[0]?.hospitalName;
		},
		itemSpecializations () {
			return this.itemWorkDetails?.spetializationData;
		},
		patientDetails () {
			return this.detailedUser?.userData;
		},
		patientMetaData () {
			return this.detailedUser?.userData?.patientMetadata;
		},
	},
	watch: {
		item (val) {
			if (val.roleId === 3) {
				this.getDoctorMetaData(val.id);
			}
			if (val.roleId === 4) {
				this.getNurseMetaData(val.id);
			}
		},
		patientId: {
			immediate: true,
			deep: true,
			async handler (newVal) {
				if (newVal !== undefined) {
					await this.getPatientDetails(newVal);
				}
			},
		},
		type: {
			immediate: true,
			deep: true,
			async handler (val) {
				if (val === 'mstaff') {
					if (this.item.roleId === 3) {
						await this.getDoctorMetaData(this.item.id);
					}
					if (this.item.roleId === 4) {
						await this.getNurseMetaData(this.item.id);
					}
				}
			},
		},
	},
	methods: {
		selectedBranch (officeSelected) {
			// console.log('selected branch: ', officeSelected.id);
		},
		async deAssignHospital (hospitalAssignedToDeassign) {
			const body = { HospitalId: hospitalAssignedToDeassign.id, UserId: this.item.id };
			await this.$store.dispatch('hospitals/deassignUserFromHospital', body)
				.then(async (res) => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success'));

						// remove from local array of assigned hospitals
						const indexOfHospitalToremove = this.item.hospitalsAssignedData.findIndex(object => {
							return object.id === hospitalAssignedToDeassign.id;
						});
						if (indexOfHospitalToremove > -1) {
							this.item.hospitalsAssignedData.splice(indexOfHospitalToremove, 1);
						}
					} else {
						showErrorAlert(this.$t('failed'));
					}
				});
		},
		showDialogAssignHospitalToUser () {
			this.assignHospitalDialog = true;
		},
		resultBackAssignedHospital (res) {
			if (res.resFlag) {
				showSuccessAlert(this.$t('success'));

				// add element to local array
				const newElement = { id: res.data.hospitalId, hospitalName: res.hospitalName };
				this.item.hospitalsAssignedData.push(newElement);

				this.closeAssignHospitalDialog();
			} else {
				showErrorAlert(this.$t('failed'));
			}
		},
		closeAssignHospitalDialog () {
			this.assignHospitalDialog = false;
		},
		getSpecializationDescription (spec) {
			return spec[this.currentLocale];
		},
		toFeet (value) {
			return fromTo(value, 'cm', 'ft-us').toFixed(2) + ' ft';
		},
		async getPatientDetails (id) {
			this.patientloading = true;
			await this.$store.dispatch('users/getUserById', id);
			this.patientloading = false;
		},
		async getDoctorMetaData (id) {
			this.loadingStaffData = true;
			await this.$store.dispatch('doctormetadata/getMetaData', id);
			this.loadingStaffData = false;
		},
		async getNurseMetaData (id) {
			this.loadingStaffData = true;
			await this.$store.dispatch('nursemetadata/getMetaData', id);
			this.loadingStaffData = false;
		},
		editstaffdetails () {
			this.editmedicalstaffdialog = true;
			this.edituser = this.item;
		},
		updatedstaffdetails (res) {
			this.editmedicalstaffdialog = false;
			this.edituser = null;
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'error',
					color: 'main_red',
					// message: res.msg,
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		editstaffdata () {
			this.editmedicaldatadialog = true;
			this.editdata = this.itemWorkDetails;
		},
		updatedstaffdata (res) {
			this.editmedicaldatadialog = false;
			this.editdata = null;
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'error',
					color: 'main_red',
					// message: res.msg,
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
			if (this.item.roleId === 3) {
				this.getDoctorMetaData(this.item.id);
			}
			if (this.item.roleId === 4) {
				this.getNurseMetaData(this.item.id);
			}
		},
	},
};
</script>
