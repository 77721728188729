<template>
    <v-dialog
        v-model="dialog"
        width="600"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        class="dialog-style"
    >
        <v-card>
            <v-card-title class="secondary py-1" style="height: 40px">
                <span class="dialog-title">{{$t('messageIconTooltip')}}</span>
                <v-spacer />
                <v-icon small color="primary" @click="$emit('close')">mdi-window-close</v-icon>
            </v-card-title>
            <v-card-text class="mt-4">
                <v-row>
                    <v-col>
                        <v-textarea
                            v-model="message"
                            outlined
                            dense
                            hide-details
                            filled
                            style="border-radius: 12px"
                            :label="$t('message')"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mr-2">
                <v-spacer></v-spacer>
                <v-btn
                    class="table-create-button"
                    @click="send"
                >
                    <span>{{ $t("messageIconTooltip") }}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: ['dialog', 'messageBody'],
	data () {
		return {
			message: null,
		};
	},
	computed: {
		...mapGetters({ delay: 'authentication/getDelay' }),
	},
	methods: {
		async send  () {
			const body = this.messageBody;
			body.messageToSend = this.message;
			body.notificationTypeId = 1;
			await this.$store.dispatch('notifications/sendMessageToMedicalTeam', body)
				.then((res) => {
					this.$store.commit('alerts/add', {
						id: 'sendMessage',
						type: 'success',
						color: 'main_green',
						// message: res.msg,
						message: this.$t('success'),
					});
					this.$emit('close');
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				});
		},
	},
};
</script>
