<template>
	<div>
		<v-row class="mx-2">
			<v-col cols="12">
				<v-card flat style="border-radius: 12px" elevation="6">
					<v-card-title class="elevation-10">
						<v-row>
							<v-col class="d-flex align-center justify-start" cols="8"
								><h5 class="d-flex align-left">{{ $t("medicalTeam") }}</h5>
							</v-col>
							<v-col cols="4">
								<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								:label="$t('search-medical-staff')"
								single-line
								hide-details
								filled
								dense
								background-color="#E8E8E8"
								rounded
								class="table-search-button"
								/>
							</v-col>
						</v-row>
					</v-card-title>
					<v-data-table
						:loading="loading"
						:headers="headers"
						:no-data-text="$t('no-data-available')"
            :loading-text="$t('loading')"
						:headers-length="headers.length"
						:search="search"
						:items="medicalTeam"
						:items-per-page="-1"
						sort-by="correlationId"
						item-key="correlationId"
						fixed-header
						dense
            class="elevation-15"
						style="cursor: pointer"
						:height="testHeight"
						group-by="[patientId]"
						:single-expand="true"
						:expanded.sync="expanded"
						ref="table"
            :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
					>
						<template v-slot:[`group.header`]="{ group, headers, items, toggle }">
              <td @click="toggle" class="text-left elevation-3" :colspan="headers.length" style="background-color:white;">
                <v-row class="align-center">
                  <v-col cols="3">
                    <span style="font-weight: bold">{{titleMap.get(group)}}</span>
                  </v-col>
                <span> {{ $t("medicalTeam") }}</span>
                  <v-col cols="1">
                    <v-chip pill x-small color="primary" class="text--black">{{ items.length }}</v-chip>
                  </v-col>
                  <v-col class="text-right">
                   <span @click="isOpenFunction()" class="mt-2">
                           <v-chip
                               v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'"
                               @click.stop="editDialog(group)"
                               pill
                               small
                               label
                               color="primary"
                               class="text--black mx-1"
                           >
                      {{ $t("edit-members") }}
                    </v-chip>
                    <v-chip  @click.stop="sendMessageToTeam(group)" pill small label color="primary" class="text--black">{{ $t("send-message") }}</v-chip>
									<v-icon color="primary" v-if="isOpen" >mdi-arrow-down-drop-circle</v-icon>
									<v-icon color="primary" v-else >mdi-arrow-up-drop-circle</v-icon>
								</span>
                  </v-col>

              </v-row>
							</td>

						</template>
						<template v-slot:item="{item}">
							<tr @click="expand(item) && deassign_dialog" >
							<td v-if="10000 < 1"></td>
							<td  class="table-cols">
								<img v-if="item.roleId === 4" :src="nurse_icon(item.status)" color="primary" height="24px"/>
								<img v-if="item.roleId === 3" :src="doctor_icon(item.status)" color="primary" height="24px"/>
								</td>
								<td  class="table-cols">{{ item.fullName }}</td>
								<td  class="table-cols">{{ item.email }}</td>
								<td  class="table-cols">{{ item.hospitalName }}</td>
								<!-- JUST FOR SAKE OF SEARCHING THE HEADER -->

								<!-- END OF JUST FOR SAKE OF SEARCHING THE HEADER -->
								<td v-if="item.id !== item.primaryDoctorId" :colspan="headers.length + 1">
									<v-chip
										v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'"
										@click="deassignUser(item)"
										pill
										style="float: right;"
										small
										label
										color="main_red"
										class="table-deassign-button table-cols-right"
									>
									{{ $t("de-assign-member") }}
									</v-chip>
								</td>
                <td v-else :colspan="headers.length + 1">
                  <v-icon
                      style="float: right;"
                  >
                    mdi-key
                  </v-icon>
                </td>
							</tr>
						</template>
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<DetailsCard v-if="expanded" :patientId="patientId" :item="item" :type="'mteam'"></DetailsCard>
							</td>
						</template>
						<template v-slot:[`footer.prepend`]>
							<v-btn
								v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'"
								class="table-create-button"
								label="AddDialog"
								@click="manageMedicalTeamDialog = true"
							>
							<img class="pt-1" height="20px" width="20px" :src="medical_team_white" style="margin-right:7%; margin-bottom: 5%;"/>
							<span style="text-transform: none;">{{ $t("addNewMedicalTeam") }}</span>
							</v-btn>
						</template>
						<!-- <template v-if="roleName === 'HOSPITAL_ADMIN'" v-slot:[`footer.prepend`]="{}">
							<v-btn
								class="table-create-button"
								label="AddDialog"
								@click="manageMedicalTeamDialog = true"
							>
								<span>{{ $t("addNewMedicalTeam") }}</span>
							</v-btn>
						</template> -->
					</v-data-table>
				</v-card>
				<v-dialog v-model="deassign_dialog" persistent max-width="290">
					<v-card>
						<v-card-title>
							<span class="primary--text">{{ $t("are-you-sure") }}</span>
						</v-card-title>
						<v-card-text>
							{{ $t("de-assign-this-member-for-this-medical-team") }}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="main_black" text @click="deassign_dialog = false">
								{{ $t("disagree") }}
							</v-btn>
							<v-btn
								color="primary"
								text
								@click="deassignMember(memberToDeassign)"
							>
							{{ $t("agree") }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<add-staff-dialog v-if="dialog" :dialog="dialog" @false="dialog = false"/>
				<ManageMedicalTeamDialog v-if="manageMedicalTeamDialog" :dialog="manageMedicalTeamDialog" :deniedHospitals="deniedHospitals" :deniedPatients="deniedPatients" :method="medicalTeamMethod" :data="medicalTeamData" @false="closeDialog()" @close="manageMedicalTeamDialog = false"/>
				<SendMessage v-if="sendMessageDialog" :dialog="sendMessageDialog" :messageBody="messageBody" @close="sendMessageDialog = false"></SendMessage>
				<AssignMedicalMember v-if="assign_dialog" :dialog="assign_dialog" :team="mteam" @false="closeDialog()" @membersChanged="success"></AssignMedicalMember>
				<MedicalTeamDialogEditVue v-if="showEditDialog" :item="editItem" :patientId="patientId" :dialog="showEditDialog " :deniedHospitals="deniedHospitals" :deniedPatients="deniedPatients" :method="medicalTeamMethod" :data="medicalTeamData" @false="closeDialog()" @close="showEditDialog = false"></MedicalTeamDialogEditVue>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import MedicalTeamDialogEditVue from '../../dialogs/MedicalTeamDialogEdit.vue';
import MedicalTeamDialogEditVue from '@/views/dialogs/MedicalTeamDialogEdit.vue';
import AssignMedicalMember from '@/views/dialogs/AssignMedicalMember.vue';
import DetailsCard from '@/views/Client/components/DetailsCard.vue';
import ManageMedicalTeamDialog from '@/views/dialogs/MedicalTeamDialogAdd';
import SendMessage from '@/views/dialogs/SendMessage';
import { pencilImg, doctorBlack, doctorSecondary, nurseBlack, nurseSecondary, medicalTeamWhite } from '@/assets';

export default {
	components: {
		AssignMedicalMember,
		DetailsCard,
		ManageMedicalTeamDialog,
		SendMessage,
		MedicalTeamDialogEditVue,
	},
	data () {
		return {
			isOpen: true,
			pencilImg: pencilImg,
			showEditDialog: false,
			expanded: [],
			editItem: null,
			medicalTeams: [],
			tempTeam: [],
			headerClass: 'black_inner--text !important;',
			loading: true,
			search: null,
			title: '',
			dialog: false,
			titleMap: new Map(),
			medicalTeamDialogEditVue: false,
			manageMedicalTeamDialog: false,
			sendMessageDialog: false,
			messageBody: {},
			medicalTeamMethod: 'add',
			deniedHospitals: [],
			medicalTeamData: {},
			medicalTeamToDelete: {},
			mteam: null,
			assign_dialog: false,
			deassign_dialog: false,
			memberToDeassign: null,
			patientId: null,
			doctor_black: doctorBlack,
			doctor_secondary: doctorSecondary,
			nurse_black: nurseBlack,
			nurse_secondary: nurseSecondary,
			medical_team_white: medicalTeamWhite,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			medicalTeamFromRepo: (state) => state.medicalteam.medicalTeams,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
			myMedicalTeam: 'authentication/getMyMedicalTeam',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
		}),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		medicalTeam () {
			var result = [];
			var i = 0;
			this.medicalTeamFromRepo.forEach((element) => {
				this.titleMap.set(element.patientId, element.medicalTeamTitle);
				element.membersList.forEach((c) => {
					i++;
					c.patientId = element.patientId;
					c.primaryDoctorId = element.primaryDoctorId;
					c.patientFullname = element.patientFullname;
					c.fullName = c.firstName.concat(' ', c.lastName);
					c.statusDescription = c.status === 1 ? 'Active' : 'Inactive';
					c.sex = c.gender === 'M' ? this.$t('male') : this.$t('female');
					c.role = c.roleId === 3 ? 'Doctor' : 'Nurse';
					c.correlationId = i;
					c.medicalTeamId = element.id;
					c.medicalTeamTitle = element.medicalTeamTitle;
					c.hospitalName = element.hospitalName;
					result.push(c);
				});
			});
			return result;
		},
		deniedPatients () {
			const d = [];
			this.medicalTeamFromRepo.forEach((element) => {
				d.push(element.patientId);
			});
			return d;
		},
		headers () {
			var headers = [];
			headers = [
				{
					text: this.$t('header-status'),
					sortable: false,
					value: 'statusDescription',
					class: this.headerClass,
				},
				{
					text: this.$t('header-full-name'),
					sortable: false,
					value: 'fullName',
					class: this.headerClass,
				},
				{
					text: this.$t('header-email'),
					sortable: false,
					value: 'email',
					class: this.headerClass,
				},
				{
					text: this.$t('header-hospital-name'),
					sortable: false,
					value: 'hospitalName',
					class: this.headerClass,
				},
				// <!-- JUST FOR SAKE OF SEARCHING THE HEADER -->
				{
					text: '',
					sortable: false,
					value: 'patientFullname',
					class: 'text-end black_inner--text !important',
				},
				// <!-- END OF JUST FOR SAKE OF SEARCHING THE HEADER -->
				{
					text: '',
					value: 'actions',
					sortable: false,
					class: 'text-end black_inner--text !important',
				},
			];
			switch (this.roleName) {
			case 'HOSPITAL_ADMIN':
				return headers;
			case 'DOCTOR':
				return headers;
			case 'NURSE':
				return headers;
			default:
				headers.pop();
				return headers;
			}
		},
	},
	async mounted () {
		await this.getMedicalTeams();
		this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userData.hospitalAssignedData.id);
	},
	methods: {
		editDialog (item) {
			this.editItem = item;
			this.showEditDialog = true;

			const selectedMedicalTeam = this.medicalTeamFromRepo.filter(mt => mt.patientId === item)[0];
			this.$store.commit('medicalteam/SELECTED_MEDICAL_TEAM', selectedMedicalTeam);
		},
		isOpenFunction () {
			this.isOpen = !this.isOpen;
			return this.isOpen;
		},
		async expand (item) {
			if (this.deassign_dialog) {
				return;
			}
			const medicalteam = this.medicalTeamFromRepo.filter((c) => {
				return c.id === item.medicalTeamId;
			});
			this.patientId = medicalteam[0].patientId;
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
		async getMedicalTeams () {
			this.toggleHeaders();

			if (this.userData.roleData.roleName === 'NURSE') {
				const headers = { nurseId: this.userData.id, hospitalId: this.userData.hospitalAssignedData.id };
				await this.$store.dispatch('medicalteam/getMedicalTeamForNurse', headers)
					.then((res) => {
						this.loading = false;
					});
			} else if (this.userData.roleData.roleName === 'HOSPITAL_ADMIN') {
				await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.userData.id);
				this.loading = false;
			} else {
				const headers = { doctorId: this.userData.id, hospitalId: this.userData.hospitalAssignedData.id };
				await this.$store
					.dispatch('medicalteam/getMedicalTeamForDoctor', headers)
					.then((res) => {
						this.loading = false;
					});
			}

			this.toggleHeaders();
		},
		async deassignUser (item) {
			this.deassign_dialog = true;
			this.memberToDeassign = item;
		},
		async deassignMember (item) {
			let flag = false;
			const body = {
				medicalTeamId: item.medicalTeamId,
				memberUserId: item.id,
			};
			this.medicalTeamToDelete = this.medicalTeamFromRepo.find(
				(element) => element.id === item.medicalTeamId,
			);
			if (this.medicalTeamToDelete.membersList.length === 1) {
				flag = true;
			}
			await this.$store
				.dispatch('medicalteam/deassignMemberToMedicalTeam', body)
				.then(async () => {
					if (flag) {
						const medicalTeamBody = {
							patientId: this.medicalTeamToDelete.patientId,
							hospitalId: this.medicalTeamToDelete.hospitalId,
						};
						await this.$store
							.dispatch('medicalteam/deleteMedicalTeam', medicalTeamBody)
							.then(() => {
								this.getMedicalTeams();
								this.deassign_dialog = false;
							});
					} else {
						this.getMedicalTeams();
						this.deassign_dialog = false;
					}
				});
		},
		assignmemberDialog (item) {
			const team = this.medicalTeamFromRepo.filter((m) => {
				return m.patientFullname === item;
			});
			this.mteam = team[0];
			if (this.mteam.length !== null) {
				this.assign_dialog = true;
			}
		},
		closeDialog () {
			this.manageMedicalTeamDialog = false;
			this.showEditDialog = false;
			this.assign_dialog = false;
			this.getMedicalTeams();
		},
		success (res) {
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'medicalTeamAdd',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'medicalTeamAdd',
					type: 'error',
					color: 'main_red',
					// message: 'Error',
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		doctor_icon (item) {
			switch (item) {
			case 1:
				return this.doctor_black;
			default:
				return this.doctor_secondary;
			}
		},
		nurse_icon (item) {
			switch (item) {
			case 1:
				return this.nurse_black;
			default:
				return this.nurse_secondary;
			}
		},
		async sendMessageToTeam (item) {
			const team = this.medicalTeamFromRepo.filter((m) => {
				return m.patientId === item;
			});
			this.mteam = team[0];
			if (this.mteam.length !== null) {
				this.messageBody = {
					userId: this.userData.id,
					medicalTeamId: this.mteam.id,
				};
				this.sendMessageDialog = true;
			}
		},
		toggleHeaders () {
			const table = this.$refs.table;
			if (table) {
				const keys = Object.keys(table?.$vnode.componentInstance.openCache);
				keys.forEach(x => {
					table.$vnode.componentInstance.openCache[x] = false;
				});
			}
		},
	},
};
</script>

<style scoped>
</style>
