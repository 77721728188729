<template>
    <v-dialog
        v-model="dialog"
        width="800"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        style="background-color: blue; border-radius: 30px !important"
    >
        <v-form v-model="valid">
            <v-card flat style="overflow: hidden">
                <v-card-title class="secondary py-1" style="height: 40px">
                    <span class="dialog-title">{{$t('work')}} {{$t('details')}}</span>
                    <v-spacer />
                    <v-icon small color="primary" @click="$emit('close')">mdi-window-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <!-- <v-row class="pl-6 pt-6 pb-0">
                        <v-col class="col-left-alignment">
                            <span style="font-size: 16px">Licence</span>
                        </v-col>
                    </v-row> -->
                    <!-- <v-row dense class="px-6 pt-0 pb-2">
                        <v-col>
                            <validation-provider name="Phone number" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="data.licenceId"
                                    label="Name *"
                                    prepend-inner-icon="mdi-account mr-1"
                                    dense
                                    background-color="#E8E8E8"
                                    rounded
                                    outlined
                                    style="border-radius: 12px !important; height: 40px"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row> -->
                    <v-row v-if="role === 3" dense class="px-6 pt-5 pb-2">
                        <v-col>
                            <v-row class="pl-4 pb-4">
                                <span style="font-size: 16px;">{{$t('specializations')}}</span>
                            </v-row>

                            <v-list dense height="220px" class="specializations-list">
                                <v-list-item-group
                                    v-model="selected"
                                    active-class="primary--text"
                                    multiple
                                >
                                    <template v-for="(item) in verifiers">
                                    <v-list-item :key="item.lowValue" :value="item.lowValue">
                                        <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title class="d-flex align-left pl-2 pt-1" v-text="item.longDescription"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-icon v-if="active" color="primary">mdi-check</v-icon>
                                        </v-list-item-action>
                                        </template>
                                    </v-list-item>
                                    </template>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-6">
                    <v-spacer />
                    <div class="my-3">
                        <v-btn class="table-cancel-button" @click="$emit('close')">
                            <span color="black--text">{{ $t("cancel") }}</span>
                        </v-btn>
						<v-btn
							class="table-create-button mx-2"
							:loading="loading"
							:disabled="loading"
							@click="loader = 'loading'"
						>
							<span>{{ $t("save") }}</span>
						</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex';

export default {
	props: ['dialog', 'data', 'role'],
	data () {
		return {
			loader: null,
			loading: false,
			valid: false,
			selected: [],
			specializations_state: null,
		};
	},
	computed: {
		...mapState({
			verifiersFromRepo: state => state.verifiers.verifiers,
		}),
		verifiers () {
			return this.verifiersFromRepo;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.update()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	async mounted () {
		await this.getSpecializations();
		this.fillSpecializations();
	},
	methods: {
		fillSpecializations () {
			if (this.data.spetializationData) {
				this.data.spetializationData.forEach(c => { this.selected.push(c.spetializationId); });
			}
			this.specializations_state = this.selected;
		},
		async update () {
			if (this.role === 3) {
				const removed = this.specializations_state.filter(x => !this.selected.includes(x));
				const added = this.selected.filter(d => !this.specializations_state.includes(d));
				if (added.length > 0) {
					const specializationbody = {
						doctorUserId: this.data.userId,
						spetializationTypeId: null,
						// hospitalId: this.data.hospitalsAssignedData[0].id,
					};
					added.forEach(async (s) => {
						specializationbody.spetializationTypeId = s;
						await this.$store.dispatch('doctormetadata/assignDoctorToSpetialization', specializationbody)
							.then(res => {
								this.$emit('updated', res);
							});
					});
				}
				if (removed.length > 0) {
					const specializationbody = {
						doctorUserId: this.data.userId,
						spetializationTypeId: null,
					};
					removed.forEach(async (s) => {
						specializationbody.spetializationTypeId = s;
						await this.$store.dispatch('doctormetadata/deAssignDoctorToSpetialization', specializationbody)
							.then(res => {
								this.$emit('updated', res);
							});
					});
				}
			}
			if (this.role === 4) {
				// const metadatabody = {
				// 	userId: this.data.userId,
				// 	metadataInfo: '',
				// 	licenceId: this.data.licenceId,
				// 	hospitalId: this.data.hospitalId,
				// };
				// await this.$store.dispatch('nursemetadata/editNurseMetaData', metadatabody)
				// 	.then(res => {
				// 		this.$emit('updated', res);
				// 	});
			}
		},
		async getSpecializations () {
			const header = {
				domainValue: 'SPECIALIZATION_TYPE',
			};
			await this.$store.dispatch('verifiers/getVerifiersForDomain', header);
		},
	},
};
</script>
